import React from 'react';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';

const headers = [
    { label: "Họ tên", key: "customer_name" },
    { label: "Email", key: "email" },
    { label: "Số điện thoại", key: "tel_num" },
    { label: "Địa chỉ", key: "address" },
];



export default function ExportCustomer(props) {

    

    return (
        <Button
            className='color-green'
            variant="contained"
            size="small"
            startIcon={<GetAppIcon />}
        >
            <CSVLink style={{color:'#fff', textDecoration: 'none'}} data={props.data} headers={headers}>
                Export CSV
            </CSVLink>
        </Button>
    );
}