import { ValidatorForm } from 'react-material-ui-form-validator';

export const passwordValidation = (props) => {
    if (props.action == 'edit') {
        props.setValidatorPass(['minLenght', 'mediumPass']);
        props.setErrorMessagesPass(['Mật khẩu hơn 5 ký tự', 'Mật khẩu có chữ hoa, thường, và số '])

    } else {
        props.setValidatorPass(['required', 'minLenght', 'mediumPass']);
        props.setErrorMessagesPass(['Mật khẩu không được trống.', 'Mật khẩu hơn 5 ký tự', 'Mật khẩu có chữ hoa, thường, và số '])
    }

}

export const repeatPasswordValidation = (props) => {
    if (props.action == 'edit') {
        props.setValidatorRepeatPass(['isPasswordMatch']);
        props.setErrorMessagesRepeatPass(['Nhập xác nhận không đúng'])
    } else {
        props.setValidatorRepeatPass(['required', 'isPasswordMatch']);
        props.setErrorMessagesRepeatPass(['Nhập xác nhận mật khẩu', 'Nhập xác nhận không đúng'])
    }
}

export const mediumPass = () => {
    ValidatorForm.addValidationRule('mediumPass', (value) => {
        const mediumRegex = new RegExp("^((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))(?=.{6,})");
        if (value && mediumRegex.test(value)) {
            return true;
        }
        return false;
    });
}

export const minLenght = () => {

    ValidatorForm.addValidationRule('minLenght', (value) => {
        const lengthMin = 6
        if (value && value.length < lengthMin ) {
            return false;
        }
        return true;

    });

}

export const checkUniqueEmail = (props) => {
    const {data,action,userId} = props

    ValidatorForm.addValidationRule('uniqueEmail', (value) => {
        for (let i = 0; i < data.length; i++) {

            if (action == 'edit') {
                if (data[i].email.toLowerCase() == value.toLowerCase() && data[i].id != userId) {
                    return false;
                }
            }else {
                if (data[i].email.toLowerCase() == value.toLowerCase()) {
                    return false;
                }
            }

        }
        return true;
    });
}

export const handleRepeatPassword = (data) => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        console.log(value)
        if (value != data) {
            return false;
        }
        return true;
    });
}

export const validationPhone = () => {

    var regexPhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    ValidatorForm.addValidationRule('phone', (phone) => {
        if (phone && phone.match(regexPhone)) {
            return true;
        }
        return false;
    });
}

