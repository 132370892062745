import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import noImage from '../../images/no-image.jpg';
import '../styles/style.css'
import { Api } from '../config/config';
export default function UploadImage(props) {
    const [imageReview, setImageReview] = React.useState('');
    const [errorImage, setErrorImage] = React.useState(false);
    const [errorMesage, setErrorMesage] = React.useState('');
    const [nameFile, setNameFile] = React.useState('');

    function onImageChange(e) {
        clearImage();
        // Update the state
        let reader = new FileReader();
        let file = ''
        file = e.target.files[0];

        let img = new Image;
        let checkImage = 0;
        let validator = false;

        if ((!file.name.match(/.(jpg|jpeg|png|gif)$/i))) {
            setErrorImage(true);
            setErrorMesage('Chỉ cho upload các file hình png, jpg, jpeg');
            validator = true;
            return false
        }
        else if (file.size / 1024 / 1024 > 2) {
            setErrorImage(true);
            setErrorMesage('Dung lượng hình không quá 2Mb');
            validator = true;
            return false
        }

        if (file.name) {
            reader.onloadend = () => {

                img.src = reader.result;

                img.onload = function () {
                    checkImage = img.naturalWidth;
                    const imagePx = 1024
                    if (checkImage > imagePx) {
                        setErrorImage(true);
                        setErrorMesage('Kích thước không quá 1024px');
                        validator = true;
                        file='';
                        
                    } else {
                        // setImageReview({
                        //     file: file,
                        //     imagePreviewUrl: reader.result
                        // });
                        setImageReview(reader.result);
                        props.onImageChange(file);
                    }
                    
                }
            }

            reader.readAsDataURL(file)
            e.target.value = ''
        }
    }

    function editLoadImage(props) {
        if(props.src) {
            let src = `${Api.serverOnline}${props.src}`;
            setImageReview(src);
        }
    }

    function clearImage() {
        setImageReview('')
        setNameFile('')
        setErrorMesage('')
    }

    useEffect(() => {
        editLoadImage(props);
    }, []);

    return (
        <Grid item container style={{ textAlign: 'center' }}  >
            <Grid item md={12} >
                <img src={imageReview ? imageReview : noImage}
                    style={{
                        width: '200px', height: 'auto', border: 1,
                        borderStyle: 'solid',
                        borderColor: '#ddd'
                    }} />
            </Grid>
            <Grid item md={12}  >
                <Box>
                    <label variant="contained" className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall' htmlFor='image' > Upload </label>
                    &nbsp;
                    <Button color='secondary'
                        size='small'
                        variant="contained"
                        onClick={clearImage} > Xóa file </Button>
                </Box>
                <input style={{ display: 'none' }} type="file" id='image'
                    name="myImage" onChange={onImageChange}
                    accept='image/png, image/jpg, image/jpeg' />
                <Box>
                    <label >  {imageReview && imageReview && imageReview.file != null ? imageReview.file.name : ''}  </label>
                </Box>
                <Box>
                    <label className='error' >  {errorImage ? errorMesage : ''}  </label>
                </Box>
            </Grid>
        </Grid>
    );
}



