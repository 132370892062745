import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

import SignIn from "./login/login";
import Product from "./products/product";
import User from "./users/user";
import Customer from './customers/customer';
import Order from './orders/order';

const PrivedRoute = ({ component: Component, path }) => {
  let token = '';
  if( sessionStorage && sessionStorage.getItem('token') ){
    token = JSON.parse(sessionStorage.getItem('token'));
  }
  return (
    <Route path={path}>
      { token ? <Component token={token} /> : <Redirect to="/login" /> }
    </Route>
  );
}

export default function AppRoute() {
  return (
    <Router>
      <div>
        
        <Switch>
          <Route exact default path="/login">
            <SignIn />
          </Route>
          <PrivedRoute exact path="/users" component={User} />
          <PrivedRoute exact path="/products" component={Product} />
          <PrivedRoute exact path="/customers" component={Customer} />
          <PrivedRoute exact path="/orders" component={Order} />
          <Route exact path="/">
             <Redirect exact to="/login"  />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}