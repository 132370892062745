import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from "axios";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import '../styles/style.css'
import SimpleBackdrop from '../layouts/backdrop'
import { Api } from '../config/config';
import { minLenght,validationPhone } from '../validations/validator';


const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);


export default function AddCustomer(props) {
	const classes = useStyles();
	const [state, setState] = React.useState({
		customer_name: "",
		email: "",
		tel_num: "",
		address: "",
		is_active: 0,
	});
	const [backDropOpen, setBackDropOpen] = React.useState(false);
	const [alertOpen, setAlertOpen] = React.useState(false);

	const storageToken = JSON.parse(sessionStorage.getItem('token'))
	const API_URL = `${Api.apiOnline}`;
	const headers = {
		"Content-Type": "application/json",
		'Authorization': 'Bearer ' + storageToken.token,
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (props.action == 'add') {
			setBackDropOpen(true)
			axios.post(`${API_URL}/customers`, { data: state, action: 'add' }, { headers })
				.then(response => {
					props.handleSubmit(response.data.data);
					props.handleClose();

				});
		} else {
			setBackDropOpen(true)
			const idCustomer = props.rowEdit.row.id
			axios.put(`${API_URL}/customers/${idCustomer}`, state, { headers })
				.then(response => {

					props.handleSubmit(response.data.data);
					props.handleClose();
				});
		}

	}

	function handleChange(event) {
		const value = event.target.value;
		setState({
			...state,
			[event.target.name]: value
		});

		if (event.target.name == 'customer_name') {
			checkMinLenght();
		}
		else if (event.target.name == 'email') {
			checkUniqueEmail();
		}
		else if (event.target.name == 'tel_num') {
			checkValidationPhone();
		}
	}

	function checkValidationPhone() {
		validationPhone();
	}

	function handleChecked(event) {
		const value = event.target.checked;
		setState({
			...state,
			[event.target.name]: value
		});
	}


	function checkUniqueEmail() {
		let data = props.data;
		ValidatorForm.addValidationRule('uniqueEmail', (value) => {
			for (let i = 0; i < data.length; i++) {
				if (props.action == 'edit') {
					if (data[i].email == value && data[i].id != props.rowEdit.row.id) {
						return false;
					}
				} else {
					if (data[i].email == value) {
						return false;
					}
				}
			}
			return true;
		});
	}

	function checkMinLenght() {
		minLenght();
	}

	function getUserById() {
		setState(props.rowEdit.row);
	}

	function closeForm() {
		props.handleClose(true);
	}

	useEffect(() => {
		checkMinLenght();
		checkUniqueEmail();
		checkValidationPhone();

		if (props.action == 'edit') {
			getUserById();
		}

	}, []);

	const handleClose = (props) => {
		props.handleClose();
	};

	return (
		<React.Fragment>

			<Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
				<ValidatorForm
					onSubmit={handleSubmit}
					noValidate
					className={classes.form}
				>
					<DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
						{props.action == 'add' ? 'Thêm' : 'Chỉnh sửa'} khách hàng
					</DialogTitle>
					<DialogContent dividers>

						<Grid container alignItems="center" >
							<Grid item md={3}  >
								Tên
							</Grid>
							<Grid item md={9}>
								<TextValidator
									variant="outlined"
									margin="dense"
									type="text"
									required
									fullWidth
									id="customer_name"
									label="Họ và tên"
									name="customer_name"
									autoFocus
									value={state.customer_name}
									validators={['required', 'minLenght']}
									errorMessages={['Vui lòng nhập tên khách hàng', 'Họ tên phải lớn hơn 5 ký tự']}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={3} >
								Email
							</Grid>
							<Grid item md={9}>
								<TextValidator
									variant="outlined"
									margin="dense"
									type="email"
									required
									fullWidth
									id="email"
									label="Email"
									autoComplete="false"
									name="email"
									value={state.email}
									validators={['required', 'isEmail', 'uniqueEmail']}
									errorMessages={['Email không được trống', 'Email không đúng định dạng', 'Email đã được đăng ký']}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={3} >
								Điện thoại
							</Grid>
							<Grid item md={9}>
								<TextValidator
									variant="outlined"
									margin="dense"
									type="text"
									required
									fullWidth
									id="tel_num"
									label="Số điện thoại"
									title="ex: xxxxxxxxxx hoặc xxx xxx xxxx"
									name="tel_num"
									value={state.tel_num}
									validators={['required', 'phone']}
									errorMessages={['Số điện thoại không được trống', 'Nhập không đúng định dạng điện thoại']}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item md={3} >
								Địa chỉ
							</Grid>
							<Grid item md={9}>
								<TextValidator
									variant="outlined"
									margin="dense"
									type="text"
									required
									fullWidth
									id="address"
									label="Địa chỉ"
									name="address"
									value={state.address}
									validators={['required']}
									errorMessages={['Địa chỉ không được trống']}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item md={3}   >
								Trạng thái
							</Grid>
							<Grid item md={9}>
								<FormControl variant="outlined" className={classes.formControl}   >
									<FormControlLabel
										control={<Checkbox checked={state.is_active == 1 ? true : false} value={state.is_active} name="is_active" color="primary" onChange={handleChecked} />}
									/>
								</FormControl>
							</Grid>

						</Grid>

					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="secondary"
							className={classes.submit}
							onClick={props.handleClose}
						>
							Hủy
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Lưu
						</Button>
					</DialogActions>
				</ValidatorForm>
			</Dialog>
			<SimpleBackdrop backDropOpen={backDropOpen} />
		</React.Fragment>
	);
}
