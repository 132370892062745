import './App.css';
import AppRoute from './components/rounter';

function App() {
  return (
    <AppRoute />
  );
}

export default App;
