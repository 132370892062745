import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Pagination from '@material-ui/lab/Pagination';
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import axios from "axios";
import Box from '@material-ui/core/Box';
import Header from '../layouts/appBar';
import Title from '../layouts/title';
import { useHistory } from 'react-router-dom';
import SimpleBackdrop from '../layouts/backdrop'
import ConfirmationDialog from '../layouts/confirmation'
import SimpleAlerts from '../layouts/alert'
import { Api } from '../config/config';
import '../styles/style.css';
import PublishIcon from '@material-ui/icons/Publish';
import CustomNoRowsOverlay from '../layouts/customNoRowsOverlay';
import AddCustomer from './add';
import ImportCustoner from './import';
import ExportCustomer from './export';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';
import { minLenght, validationPhone } from '../validations/validator';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '100%'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    borderInput: {
        border: 1,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
        borderColor: '#000',
        borderStyle: 'solid',
    },
    select: {
        font: 'inherit',
        padding: 5,
        fontSize: 'inherit',
        borderRadius: 5,
    },
    flexItem: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        flexWrap: 'wrap',
        marginRight: '5%',
        display: 'flex',
        flexDirection: 'column',
    },
    selectPadding: {
        padding: 2,
    }
}));




export default function Customer(token) {
    
    const classes = useStyles();
    const [dataExport, setDataExport] = React.useState([]);
    const [rowId, setRowId] = React.useState(0);
    const [importOpen, setImportOpen] = React.useState(false);
    const [idDelete, setIdDelete] = React.useState('');
    const [backDropOpen, setBackDropOpen] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [rowEdit, setRowEdit] = React.useState('');
    const [action, setAction] = React.useState('');
    const [dataBackup, setDataBackup] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pagePagination, setPagePagination] = React.useState(1);
    const [recorView, setRecorView] = React.useState(1);
    const [totalPagination, setTotalPagination] = React.useState(1);
    const [popupAdd, setPopupAdd] = React.useState(false);
    const [alertOpen, SetAlertOpen] = React.useState(false);


    const history = useHistory();

    const [state, setState] = React.useState({
        customer_name: "",
        email: "",
        address: "",
        tel_num: "",
        is_active: "",
    });

    const [stateEdit, setStateEdit] = React.useState({
        customer_name: "",
        email: "",
        address: "",
        tel_num: "",
        is_active: "",
        customer_id: "",
    });

    const API_URL = `${Api.apiOnline}/customers`;
    const headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token.token.token,
    };

    const handleChange = (event, value) => {
        setPage(value - 1);
        setPagePagination(value);
        setRecorView(1 + ((value * pageSize) - pageSize))
    };

    const keyCodeEnter = (event) => {
        if (event.keyCode == 13) {
            handleSubmit(event);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.customer_name == '' && state.email == '' && state.address == '' && state.is_active == '') {
            return false
        }

        let urlSearch = `?customer_name=${state.customer_name}&email=${state.email}&address=${state.address}&is_active=${state.is_active}`
        history.push(urlSearch);

        axios.post(`${API_URL}/search`, { state }, { headers }).then((response) => {
            setData(response.data.data);
            setDataExport(response.data.data);
            let lengthData = response.data.data.length;
            let totalPagination = Math.ceil(lengthData / pageSize)
            setTotalPagination(totalPagination)
        });
    }

    const reloadData = (data) => {
        if (data == 1) {
            setPopupAdd(false)
            setBackDropOpen(false);
            callAPI();
            showAlert();
        } else if (typeof data === 'object') {
            showAlert();
            callAPI();
            setBackDropOpen(false);
            setPopupAdd(false)
        }
    }

    function handleClear() {
        setState({
            customer_name: "",
            address: "",
            email: "",
            is_active: "",
        })
        setData(dataBackup);
        let lengthData = dataBackup.length;
        let totalPagination = Math.ceil(lengthData / pageSize)
        setTotalPagination(totalPagination)
        // console.log(dataBackup);
    }
    function handleInputChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });

        if (event.target.name == 'email') {
            checkUniqueEmail();
        }
        if (event.target.name == 'tel_num') {
            validationPhone();
        }
    }

    function handleAdd() {
        setPopupAdd(true);
        setAction('add');
    }

    function handleEdit(params) {
        setAction('edit');
        setRowEdit(params);
        setStateEdit(params.row)
        setRowId(params.id);
        checkUniqueEmail(params.id);
    }

    function handleDelete(id, action) {
        // delete and block user
        setIdDelete(id);
        setAction(action);
    }

    const handleClose = () => {
        setPopupAdd(false);
        setImportOpen(false);
    };

    const handleOK = () => {

        setBackDropOpen(true);

        axios.post(`${API_URL}/${idDelete}`, { action }, { headers }).then((response) => {
            if (response.data.data) {
                callAPI();
                setBackDropOpen(true);
            }
        });

    }

    const callAPI = async () => {
        try {
            axios.get(API_URL, { headers })
                .then((data) => {
                    let dataRes = data.data.data;
                    setData(dataRes);
                    setDataExport(dataRes.slice(0, 9))
                    setDataBackup(dataRes);
                    let lengthData = dataRes.length;
                    let totalPagination = Math.ceil(lengthData / pageSize)
                    setTotalPagination(totalPagination)
                    setBackDropOpen(false)
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleImportSuccess = (dataImport) => {
        callAPI();
    }

    const handleImport = () => {
        setImportOpen(true)
    }

    const handleEditRow = (event) => {
        const value = event.target.value;
        setStateEdit({
            ...stateEdit,
            [event.target.name]: value
        });

        if (event.target.name == 'email') {
            checkUniqueEmail();
        }
        if (event.target.name == 'tel_num') {
            validationPhone();
        }

    }

    function showAlert() {
        SetAlertOpen(true)
        setTimeout(() => {
            SetAlertOpen(false)
        }, 2000);
    }

    const updateSubmit = (event) => {
        event.preventDefault();
        // console.log(stateEdit);
        
        setBackDropOpen(true)
        axios.put(`${API_URL}/${rowId}`, stateEdit, { headers })
            .then(response => {
                setBackDropOpen(false)
                callAPI();
                setRowId(0);
                showAlert();
            });

        
    }

    const checkUniqueEmail = (id) => {
        ValidatorForm.addValidationRule('uniqueEmail', (value) => {
            for (let i = 0; i < data.length; i++) {
                if (id > 0 ) {
                    if (data[i].email.toLowerCase() == value.toLowerCase() && data[i].customer_id != id) {
                        return false;
                    }
                } else {
                    if (data[i].email.toLowerCase() == value.toLowerCase()) {
                        return false;
                    }
                }

            }
            return true;
        });
    }

    useEffect(() => {
        callAPI();
        const params = new URLSearchParams(window.location.search);
        checkUniqueEmail();
        validationPhone();
        minLenght();
        
    }, []);

    const columsData = [
        {
            field: '#', headerName: '', renderCell: (params) => {
                return params.id
            }
        },
        {
            field: 'customer_name', headerName: 'Họ tên', flex: 0.75,
            renderCell: (params) => {
                if (rowId == params.id) {
                    return <TextValidator
                        value={stateEdit.customer_name}
                        name='customer_name'
                        autoFocus
                        placeholder="Họ tên"
                        fullWidth
                        onChange={handleEditRow}
                        validators={['required', 'minLenght']}
                        errorMessages={['Vui lòng nhập tên khách hàng', 'Họ tên lớn hơn 5 ký tự']}
                    />
                }
            },

        },
        {
            field: 'email', headerName: 'Email', flex: 1,

            renderCell: (params) => {
                if (rowId == params.id) {
                    return <TextValidator
                        value={stateEdit.email}
                        name='email'
                        fullWidth
                        autoComplete="off"
                        placeholder="Email"
                        onChange={handleEditRow}
                        validators={['required', 'isEmail', 'uniqueEmail']}
                        errorMessages={['Email không được trống', 'Email không đúng định dạng', 'Email đã được đăng ký']}
                    />
                }
            },

        },
        {
            field: 'address', headerName: 'Địa chỉ', flex: 2,

            renderCell: (params) => {
                if (rowId == params.id) {
                    return <TextValidator
                        value={stateEdit.address}
                        name='address'
                        fullWidth
                        placeholder="Địa chỉ"
                        onChange={handleEditRow}
                        validators={['required']}
                        errorMessages={['Vui lòng nhập địa chỉ']}
                    />
                }
            },

        },
        {
            field: 'tel_num', headerName: 'Số điện thoại', flex: 1,

            renderCell: (params) => {
                if (rowId == params.id) {
                    return <TextValidator
                        value={stateEdit.tel_num}
                        fullWidth
                        title="ex: xxxxxxxxx hoặc xxx xxx xxxx"
                        name='tel_num'
                        placeholder="Số điện thoại"
                        onChange={handleEditRow}
                        validators={['required', 'phone']}
                        errorMessages={['Số điện thoại không được trống', 'Nhập không đúng định dạng điện thoại']}
                    />
                }
            },

        },
        {
            field: '', headerName: null, flex: 0.5, sortable: false,
            disableColumnMenu: true, renderCell: (params) => {
                const onClickEdit = () => {
                    setRowId(params.id);
                    handleEdit(params);
                };
                return (
                    <Box display='flex'>
                        {
                            rowId == params.id ?

                                <Button style={{ padding: 0 }} type='submit' color='primary'
                                    startIcon={<SaveIcon fontSize="large" />} />

                                :
                                <EditIcon
                                    id={params.customer_id}
                                    color='primary'
                                    onClick={onClickEdit}

                                />

                        }

                    </Box>
                )
            },
        }];

    const rowsData = data;

    const handleGetRowId = (row) => {
        return row.customer_id
    }



    // const apiRef = React.useRef<GridApi>(null);
    return (
        <React.Fragment  >
            <Header />
            <Box position='relative' >
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                >
                    <Grid container spacing={0} style={{ padding: 20,paddingBottom:0 }} >
                        <Title name='Danh sách khách hàng' />

                        <Grid item md={12}>

                            <Box display='flex'>
                                <Box className={classes.flexItem} >
                                    <b>Tên</b>
                                    <TextField
                                        name='customer_name'
                                        margin="dense"
                                        placeholder="Nhập họ tên"
                                        label=""
                                        variant="outlined"
                                        value={state.customer_name}
                                        onKeyDown={(e) => keyCodeEnter(e)}
                                        onChange={handleInputChange} />
                                </Box>
                                <Box className={classes.flexItem}>
                                    <b>Email</b>
                                    <TextField name='email'
                                        margin="dense"
                                        placeholder="Nhập email"
                                        label=""
                                        variant="outlined"
                                        value={state.email}
                                        onKeyDown={(e) =>
                                            keyCodeEnter(e)}
                                        onChange={handleInputChange} />
                                </Box>
                                <Box className={classes.flexItem}>
                                    <b>Địa chỉ</b>

                                    <TextField
                                        name='address'
                                        margin="dense"
                                        placeholder="Nhập đại chỉ"
                                        label=""
                                        variant="outlined"
                                        value={state.address}
                                        onKeyDown={(e) => keyCodeEnter(e)}
                                        onChange={handleInputChange} />
                                </Box>
                                <Box className={classes.flexItem}>
                                    <FormControl variant="outlined" className={classes.formControl}   >
                                        <b>Trạng thái</b>
                                        <Select
                                            native
                                            style={{ marginTop: 8 }}
                                            margin="dense"
                                            name='is_active'
                                            value={state.is_active}
                                            onChange={handleInputChange}
                                        >
                                            <option value='' >Chọn trạng thái</option>
                                            <option value='1' >Đang hoạt động</option>
                                            <option value='0' >Tạm khóa</option>
                                        </Select>
                                    </FormControl>
                                </Box>


                            </Box>
                        </Grid>
                        <Grid container style={{ marginTop: 10 }} >
                        </Grid>
                        <Grid container>

                            <Grid item md={4}>
                                <Box display='flex' justifyContent='space-between' className='wrap-import'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<PersonAddIcon />}
                                        onClick={handleAdd}
                                    >
                                        Thêm mới
                                    </Button>

                                    <Button
                                        className='color-green'
                                        variant="contained"
                                        size="small"
                                        startIcon={<PublishIcon />}
                                        onClick={handleImport}
                                    >
                                        Import CSV
                                    </Button>
                                    <ExportCustomer data={dataExport} />
                                </Box>
                            </Grid>
                            <Grid item xs={1} md={5} >

                            </Grid>

                            <Grid item md={1} >
                                <Button
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    className={classes.button}
                                    startIcon={<SearchIcon />}
                                >
                                    Tìm kiếm
                                </Button>

                            </Grid>
                            <Grid item md={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    className={classes.button}
                                    startIcon={<ClearIcon />}
                                    onClick={handleClear}
                                >
                                    Xóa tìm
                                </Button>
                            </Grid>

                        </Grid>

                        <Grid container style={{ marginTop: 10 }} ></Grid>
                        
                        <Grid item md={12}  >
                            <Box position="relative" style={{ height: 40 }}  >
                                <Box display="flex" justifyContent="center"  >
                                    {data.length > 10 ?
                                        <Pagination count={totalPagination} page={pagePagination} onChange={handleChange} color="primary" showFirstButton showLastButton />
                                        : " "
                                    }
                                </Box>
                                <Box position="absolute" right={0} top={0} > Hiển thị từ {data.length > 0 ? recorView : 0} ~ {pagePagination * pageSize > data.length ? data.length : (pagePagination * pageSize)} trong tổng số <b>{data.length}</b>  user</Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
                <ValidatorForm onSubmit={updateSubmit} >
                    <Grid container spacing={0} style={{ padding: 20,paddingTop:0 }} >

                        <Grid item xs={12} md={12} >
                            <div style={{ height: '100%', width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                            autoHeight
                                            page={page}
                                            columns={columsData}
                                            rows={rowsData}
                                            pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            rowsPerPageOptions={[10, 20, 50]}
                                            hideFooterPagination={true}
                                            disableSelectionOnClick
                                            hideFooter
                                            disableColumnFilter={true}
                                            disableColumnMenu={true}
                                            disableColumnSelector={true}
                                            ColumnUnsortedIcon={true}
                                            getRowId={handleGetRowId}
                                            components={{
                                                NoRowsOverlay: CustomNoRowsOverlay,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </ValidatorForm>

                {popupAdd == true ? <AddCustomer data={dataBackup} rowEdit={rowEdit} action={action} open={popupAdd} handleClose={handleClose} handleSubmit={reloadData} /> : null}

                <SimpleBackdrop backDropOpen={backDropOpen} />
                <ImportCustoner open={importOpen} handleClose={handleClose} handleImportSuccess={handleImportSuccess} />
                <SimpleAlerts className='alert-sucess' content={ action !='add' ? 'Chỉnh sửa' : 'Thêm mới' } alertOpen={alertOpen} handleClose={handleClose} />

            </Box>
        </React.Fragment>
    );
}



