import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Pagination from '@material-ui/lab/Pagination';
import { DataGrid, GridOverlay, GridApi, GridColDef } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import axios from "axios";
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import Box from '@material-ui/core/Box';
import Header from '../layouts/appBar';
import Title from '../layouts/title';
import { useHistory } from 'react-router-dom';
// import { useParams } from "react-router-dom";
import SimpleBackdrop from '../layouts/backdrop'
import ConfirmationDialog from '../layouts/confirmation'
import { Api } from '../config/config';
import CustomNoRowsOverlay from '../layouts/customNoRowsOverlay';
import '../styles/style.css';
import LockIcon from '@material-ui/icons/Lock';
import SimpleAlerts from '../layouts/alert'
import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import OrderDetail from './order-detail';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '100%'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    borderInput: {
        border: 1,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
        borderColor: '#000',
        borderStyle: 'solid',
    },
    select: {
        font: 'inherit',
        padding: 5,
        fontSize: 'inherit',
        borderRadius: 5,
    },
    flexItem: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        flexWrap: 'wrap',
        marginRight: '5%',
        display: 'flex',
        flexDirection: 'column',
    },
    selectPadding: {
        padding: 2,
    }
}));

const handleGetRowId = (row) => {
    return row.product_id
}

const createSTT = (props) => {

    let array = props.temp
    if (array.indexOf(props.id) == -1) {
        array.push(props.value)
        props.setTemp(array);
        return array.length;
    }

}



export default function Order(token) {
    const wrapper = React.createRef();
    const classes = useStyles();
    const [stt, setSTT] = React.useState(1);


    const [idDelete, setIdDelete] = React.useState('');

    const [temp, setTemp] = React.useState([]);

    const [backDropOpen, setBackDropOpen] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [rowEdit, setRowEdit] = React.useState('');
    const [action, setAction] = React.useState('');
    const [dataBackup, setDataBackup] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pagePagination, setPagePagination] = React.useState(1);
    const [recorView, setRecorView] = React.useState(1);
    const [totalPagination, setTotalPagination] = React.useState(1);
    const [popupAdd, setPopupAdd] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [nameDelete, setNameDelete] = React.useState('');
    const [contentDelete, setContentDelete] = React.useState('');
    const [alertOpen, SetAlertOpen] = React.useState(false);
    const [detailOpen, setDetailOpen] = React.useState(false);


    const history = useHistory();

    const [state, setState] = React.useState({
        product_name: "",
        price_start: "",
        price_end: "",
        is_sales: "",
    });

    const API_URL = `${Api.apiOnline}/products`;
    const headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token.token.token,
    };

    const handleChange = (event, value) => {
        setPage(value - 1);
        setPagePagination(value);
        setRecorView(1 + ((value * pageSize) - pageSize))
    };

    const keyCodeEnter = (event) => {
        if (event.keyCode == 13) {
            handleSubmit(event);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let urlSearch = `?name=${state.name}&email=${state.email}&group_role=${state.group_role}&is_active=${state.is_active}`
        history.push(urlSearch);

        if (state.name == '' && state.email == '' && state.group_role == '' && state.is_active == '') {
            return false
        }

        axios.post(`${API_URL}/search`, { state }, { headers }).then((response) => {
            setData(response.data.data);
            let lengthData = response.data.data.length;
            let totalPagination = Math.ceil(lengthData / pageSize)
            setTotalPagination(totalPagination)

        });
    }

    const reloadData = (data) => {
        if (data == 1) {
            setPopupAdd(false)
            setBackDropOpen(false);
            callAPI();
            showAlert();
        } else if (typeof data == 'object') {
            callAPI();
            setBackDropOpen(false);
            setPopupAdd(false)
            showAlert();
        }
    }

    function handleClear() {
        setState({
            product_name: "",
            price_end: "",
            price_start: "",
            is_sales: "",
        })
        setData(dataBackup);
        let lengthData = dataBackup.length;
        let totalPagination = Math.ceil(lengthData / pageSize)
        setTotalPagination(totalPagination)
        // console.log(dataBackup);
    }
    function handleInputChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    function handleAdd() {
        setPopupAdd(true);
        setAction('add');
    }

    function handleEdit(id) {
        setPopupAdd(true);
        setAction('edit');
        setRowEdit(id);
    }

    function handleDelete(id, action) {
        // delete and block user
        setIdDelete(id);
        setAction(action);
        setConfirmOpen(true)

    }

    const handleClose = () => {
        setPopupAdd(false);
        setConfirmOpen(false);
        setDetailOpen(false);
    };

    const handleOK = () => {

        setConfirmOpen(true);
        setBackDropOpen(true);

        axios.get(`${API_URL}/delete/${idDelete}`, { headers }).then((response) => {
            if (response.data.data) {
                callAPI();
                setBackDropOpen(true);
                setConfirmOpen(false);
            }
        });

    }

    const callAPI = async () => {
        try {
            axios.get(API_URL, { headers })
                .then((data) => {
                    setData(data.data.data);
                    setDataBackup(data.data.data);
                    let lengthData = data.data.data.length;
                    let totalPagination = Math.ceil(lengthData / pageSize)
                    setTotalPagination(totalPagination)
                    setBackDropOpen(false)
                });
        } catch (e) {
            console.log(e);
        }
    };



    useEffect(() => {
        // createSTT(0);
        const params = new URLSearchParams(window.location.search)

        // let name = params.get('name')
        // console.log(name)
        callAPI();

    }, []);

    function showAlert() {
        SetAlertOpen(true)
        setTimeout(() => {
            SetAlertOpen(false)
        }, 2000);
    }

    const columsData = [
        {
            field: '#', headerName: 'Mã hóa đơn', flex: 1, renderCell: (params) => {

                return (
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <img src={`${Api.serverOnline}${params.row.product_image}`} style={{ width: '150px', height: 'auto' }} />
                            </React.Fragment>
                        }

                    >
                        <label>{params.id}</label>
                    </HtmlTooltip>
                )
            }

        },
        { field: 'product_name', headerName: 'Khách hàng', flex: 1, },
        {
            field: 'description', headerName: 'Địa chỉ', flex: 1, renderCell: (params) => {

                return (
                    <label className="not-found-controller"
                        dangerouslySetInnerHTML={{ __html: params.row.description }} />

                )
            }
        },
        {
            field: 'product_price', headerName: 'Tổng tiền', flex: 1,
            renderCell: (params) => (
                <label >
                    $ {parseFloat(params.row.product_price)}
                </label>
            )

        },
        {
            field: 'is_sales', headerName: 'Trạng thái', flex: 1,
            renderCell: (params) => (
                <label style={{ color: (params.value == 1 || params.value == 2) ? 'green' : 'red' }} >
                    {params.value == 1 ? 'Đang bán' : (params.value == 0) ? 'Ngừng bán' : 'Hết hàng'}
                </label>
            )
        },
        {
            field: '', headerName: 'action', flex: 1, sortable: false,
            disableColumnMenu: true, renderCell: (params) => {
                const onClickDelete = async () => {
                    // return alert(JSON.stringify(params.row, null, 4));
                    handleDelete(params.id, { id_delete: true })
                    setNameDelete(params.row.product_name);
                    setContentDelete('Bạn muốn xóa sản phẩm');
                };
                const onClickEdit = async () => {
                    // return alert(JSON.stringify(params.row, null, 4));
                    handleEdit(params);
                };
                return (
                    <Box display='flex'>
                        {/* <FormControl variant="outlined" className={classes.formControl}   >
                            <Select
                                native={true}
                                style={{ marginTop: 8 }}
                                margin="dense"
                                name='is_sales'
                                value={state.is_sales}
                                onChange={handleInputChange}
                            >
                                <option value='' >Chọn trạng thái</option>
                                <option value='1' >Hoàng thành</option>
                                <option value='0' >Đang xử lý</option>
                                <option value='2' >Hủy đơn</option>
                            </Select>
                        </FormControl> */}

                        <Select
                            // native
                            placeholder='chọn trạng thái'
                            // margin="dense"
                            value='-1'
                            fullWidth
                        >
                            <option value='-1' >Chọn trạng thái</option>
                            <option value='1' >Hoàng thành</option>
                            <option value='0' >Đang xử lý</option>
                            <option value='2' >Hủy đơn</option>
                        </Select>

                    </Box>
                )
            },
        }];

    const NoRow = () => {
        return (
            <label>Không có dự liệu</label>
        )
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    function handleOrderDetail() {
        setDetailOpen(true);
    }
    const rowsData = data;

    const columDataExample = [
        { field: 'id', headerName: 'Hóa đơn', flex: 0.5, sortable: false },
        {
            field: 'name', headerName: 'Khách hàng', flex: 1, sortable: false,
            renderCell: (params) => (
                <div className='order-customer' >
                    <label>{params.row.name}</label> <br />
                    <label>SDT: {params.row.phone}</label>
                </div>
            )
        },
        { field: 'address', headerName: 'Địa chỉ', flex: 1, sortable: false },
        {
            field: 'date', headerName: 'Ngày đặt', flex: 1, sortable: false,
            renderCell: (params) => (
                <div className='order-customer' >
                    <label>{params.row.date}</label>
                </div>
            )

        },
        { field: 'total', headerName: 'Tổng tiền', flex: 1, sortable: false },
        { field: 'status', headerName: 'Tình trạng', flex: 1, sortable: false },
        {
            field: '', headerName: '', flex: 1, sortable: false,
            renderCell: (params) => {
                const onClickXuLy = async () => {
                    handleOrderDetail();
                };
                return (<div>
                    <Button variant="contained"
                        color="primary" onClick={onClickXuLy}  >Xử lý</Button>
                </div>
                )
            }
        },
    ];

    const rowsDataExample = [
        { id: 'HD0001', product: 'Sản phẩm 1', name: 'Nguyen Van A', address: "Đường 1", phone: '1234567890', date: '2021/09/01 15:05:00', 'total': '$ 50', status: 'đang xử lý' },
        { id: 'HD0002', product: 'Sản phẩm 2', name: 'Nguyen Van B', address: "Đường 2", phone: '1234567890', date: '2021/08/01 15:05:00', 'total': '$ 20', status: 'đang xử lý' },
        { id: 'HD0003', product: 'Sản phẩm 3', name: 'Nguyen Van C', address: "Đường 3", phone: '1234567890', date: '2021/07/02 15:05:00', 'total': '$ 10', status: 'đang xử lý' },
        { id: 'HD0004', product: 'Sản phẩm 4', name: 'Nguyen Van D', address: "Đường 4", phone: '1234567890', date: '2021/07/01 15:05:00', 'total': '$ 30', status: 'đang xử lý' },
        { id: 'HD0005', product: 'Sản phẩm 5', name: 'Nguyen Van E', address: "Đường 5", phone: '1234567890', date: '2021/05/05 15:05:00', 'total': '$ 70', status: 'đang xử lý' },
        { id: 'HD0006', product: 'Sản phẩm 6', name: 'Nguyen Van F', address: "Đường 6", phone: '1234567890', date: '2021/05/03 15:05:00', 'total': '$ 60', status: 'đang xử lý' },
        { id: 'HD0007', product: 'Sản phẩm 7', name: 'Nguyen Van G', address: "Đường 7", phone: '1234567890', date: '2021/05/03 15:05:00', 'total': '$ 80', status: 'đang xử lý' },
        { id: 'HD0008', product: 'Sản phẩm 8', name: 'Nguyen Van H', address: "Đường 8", phone: '1234567890', date: '2021/05/02 15:05:00', 'total': '$ 10', status: 'đang xử lý' },
        { id: 'HD0009', product: 'Sản phẩm 9', name: 'Nguyen Van I', address: "Đường 9", phone: '1234567890', date: '2021/05/01 15:05:00', 'total': '$ 20', status: 'đang xử lý' }
    ];

    // const apiRef = React.useRef<GridApi>(null);
    return (
        <React.Fragment  >
            <Header />
            <Box position='relative' >
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={0} style={{ padding: 20 }} >
                        <Title name='Danh sách order' />

                        <Grid container >

                            <Grid item md={4}>
                                <Box className={classes.flexItem} >
                                    <b>Tìm đơn hàng</b>
                                    <TextField name='product_name' margin="dense" placeholder="Nhập đơn hàng" label="" variant="outlined" value={state.product_name} onKeyDown={(e) => keyCodeEnter(e)} onChange={handleInputChange} />
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.flexItem}>
                                    <FormControl variant="outlined" className={classes.formControl}   >
                                        <b>Trạng thái</b>
                                        <Select
                                            native={true}
                                            style={{ marginTop: 8 }}
                                            margin="dense"
                                            name='is_sales'
                                            value={state.is_sales}
                                            onChange={handleInputChange}
                                        >
                                            <option value='' >Chọn trạng thái</option>
                                            <option value='1' >Hoàng thành</option>
                                            <option value='0' >Đang xử lý</option>
                                            <option value='2' >Hủy đơn</option>

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid position="relative" className='price-start' item md={2}>
                                <Box className={classes.flexItem} >
                                    <b>Ngày bắt đầu</b>
                                    <TextField type='date' name='price_start' margin="dense" placeholder="Giá bán từ" label="" variant="outlined" value={state.price_start} onKeyDown={(e) => keyCodeEnter(e)} onChange={handleInputChange} />
                                </Box>
                            </Grid>
                            <Grid item md={2}>
                                <Box className={classes.flexItem} >
                                    <b>Ngày kết thúc</b>
                                    <TextField type='date' name='price_end' margin="dense" placeholder="Giá bán đến" label="" variant="outlined" value={state.price_end} onKeyDown={(e) => keyCodeEnter(e)} onChange={handleInputChange} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 10 }} >
                        </Grid>
                        <Grid container>

                        </Grid>

                        <Grid container style={{ marginTop: 10 }} ></Grid>
                        <SimpleAlerts alertOpen={alertOpen} handleClose={handleClose} />
                        {/* <Grid item md={12}  >
                            <Box position="relative" style={{ height: 40 }}  >
                                <Box display="flex" justifyContent="center"  >
                                    {data.length > 10 ?
                                        <Pagination count={totalPagination} page={pagePagination} onChange={handleChange} color="primary" showFirstButton showLastButton />
                                        : " "
                                    }
                                </Box>
                                {data.length > 0 ?
                                    <Box position="absolute" right={0} top={0} > Hiển thị từ {data.length > 0 ? recorView : 0} ~ {pagePagination * pageSize > data.length ? data.length : (pagePagination * pageSize)} trong tổng số <b>{data.length}</b>  user</Box>
                                    : ""
                                }
                            </Box>
                        </Grid> */}

                        <Grid item xs={12} md={12} >
                            <div style={{ height: '100%', width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                            autoHeight
                                            page={page}
                                            // pagination
                                            // loading={true}
                                            columns={columDataExample}
                                            rows={rowsDataExample}
                                            pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            rowsPerPageOptions={[10, 20, 50]}
                                            hideFooterPagination={true}
                                            disableSelectionOnClick={true}
                                            hideFooter
                                            disableColumnFilter={true}
                                            disableColumnSelector={true}
                                            disableColumnMenu={true}
                                            disableColumnSelector={true}
                                            ColumnUnsortedIcon={true}
                                            isCellEditable={false}
                                            // getRowId={handleGetRowId}
                                            components={{
                                                NoRowsOverlay: CustomNoRowsOverlay,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </form>

                {/* <SimpleBackdrop backDropOpen={backDropOpen} />
                <ConfirmationDialog confimOpen={confirmOpen} content={contentDelete} nameDelete={nameDelete} handleClose={handleClose} handleOK={handleOK} />
                <SimpleAlerts className='alert-sucess' content={action != 'add' ? 'Chỉnh sửa' : 'Thêm mới'} alertOpen={alertOpen} handleClose={handleClose} /> */}
                <OrderDetail open={detailOpen} handleClose={handleClose} />

            </Box>
        </React.Fragment>
    );
}

