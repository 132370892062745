import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import axios from "axios";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import '../styles/style.css'
import SimpleBackdrop from '../layouts/backdrop'
import { Api } from '../config/config';
import Editor from './ckeditor';
import UploadImage from './uploadImage';
import {minLenght} from '../validations/validator'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);


export default function AddUser(props) {
	const classes = useStyles();
	const [state, setState] = React.useState({
		product_name: "",
		product_image: "",
		product_price: "",
		is_sales: '',
		description: "",
	});
	const [backDropOpen, setBackDropOpen] = React.useState(false);
	const [error, setError] = React.useState('');

	const [imageProduct, setImageProduct] = React.useState('');
	let storageToken = JSON.parse(sessionStorage.getItem('token'))
	const API_URL = `${Api.apiOnline}/products`;
	const headers = {
		"Content-Type": "application/json",
		'Authorization': 'Bearer ' + storageToken.token,
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const formData = new FormData();
		
		if( imageProduct ) {
			console.log('imageProduct');
			formData.append("file", imageProduct);
		}
		formData.append("state",JSON.stringify(state));
		
		if (props.action == 'add') {
			// setBackDropOpen(true)
			axios.post(`${API_URL}`, formData, { headers })
				.then(response => {
					props.handleSubmit(response.data.data);
					props.handleClose();
				});
		} else {
			// setBackDropOpen(true)
			axios.post(`${API_URL}/update/${props.rowEdit.id}`, formData, { headers })
				.then(response => {
					props.handleSubmit(response.data.data);
					props.handleClose();
				});
		}

	}

	function onChangeEditor(data) {
		setState({
			...state,
			description: data
		});
	}

	function handleChange(event) {
		const value = event.target.value;

		setError('');
		setState({
			...state,
			[event.target.name]: value
		});

		if (event.target.name == 'name') {
			minLenght();
		}
	}

	function handleInputChange() {

	}

	function handleChecked(event) {
		const value = event.target.checked;
		setState({
			...state,
			[event.target.name]: value
		});
	}

	function getRowEdit() {
		setState(props.rowEdit.row);
	}

	function closeForm() {
		props.handleClose(true);
	}


	const onImageChange = (image) => {
		setImageProduct(image);
	}

	useEffect(() => {
		
		minLenght();

		if (props.action == 'edit') {
			getRowEdit();
		}

	}, []);


	return (
		<React.Fragment>

			<Dialog onClose={props.handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				// fullWidth={'true'}
				maxWidth={'md'}
			>
				<ValidatorForm
					onSubmit={handleSubmit}
					noValidate
					className={classes.form}
					encType="multipart/form-data"
				>
					<DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
						{props.action == 'add' ? 'Thêm' : 'Chỉnh sửa'} sản phẩm
					</DialogTitle>
					<DialogContent dividers>

						<Grid container alignItems="center" >
							<Grid item md={7} >
								<Grid container alignItems="center" >
									<Grid item md={3}  >
										Tên sản phẩm
									</Grid>
									<Grid item md={9}>
										<TextValidator
											variant="outlined"
											margin="dense"
											type="text"
											required
											fullWidth
											id="product_name"
											label="Nhập tên sản phẩm"
											name="product_name"
											autoFocus
											value={state.product_name}
											validators={['required', 'minLenght']}
											errorMessages={['Vui lòng nhập tên người sử dụng', 'Họ tên phải lớn hơn 5 ký tự']}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item md={3} >
										Giá bán
									</Grid>
									<Grid item md={9}>
										<TextValidator
											variant="outlined"
											margin="dense"
											type="text"
											required
											fullWidth
											id="product_price"
											label="Nhập giá bán"
											name="product_price"
											value={state.product_price}
											validators={['required','minNumber:0']}
											errorMessages={['Giá bán không được trống','Giá bán không được nhỏ hơn 0']}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item md={3}  >
										Mô tả sản phẩm
									</Grid>
									<Grid item md={9}>
										<Editor onChange={onChangeEditor} dataEditor={state.description} />
									</Grid>
									<Grid item md={3}  >
										Trạng thái
									</Grid>
									<Grid item md={9}>
										<FormControl variant="outlined" fullWidth  >
											<TextValidator
												style={{ marginTop: 8 }}
												name='is_sales'
												select
												native={'true'}
												margin="dense"
												value={state.is_sales}
												onChange={handleChange}
												fullWidth
												id="outlined-basic"
												label="Chọn trạng thái"
												variant="outlined"
												validators={['required']}
												errorMessages={['Trạng thái không được để trống']}
											>
												<option aria-label="none" value=""> Chọn trạng thái </option>
												<option value='1' >Đang bán</option>
												<option value='0' >Ngừng bán</option>
												<option value='2'>Hết hàng</option>
											</TextValidator>
										</FormControl>

									</Grid>
								</Grid>
							</Grid>
							<Grid item md={5}>
								<UploadImage onImageChange={onImageChange} src={state.product_image} />
							</Grid>
						</Grid>

					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="secondary"
							className={classes.submit}
							onClick={props.handleClose}
						>
							Hủy
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Lưu
						</Button>
					</DialogActions>
				</ValidatorForm>
			</Dialog>
			<SimpleBackdrop backDropOpen={backDropOpen} />
		</React.Fragment>
	);
}

