import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from "axios";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import '../styles/style.css'
import SimpleBackdrop from '../layouts/backdrop'
import { Api } from '../config/config';
import { minLenght, validationPhone } from '../validations/validator';
import { DataGrid } from '@material-ui/data-grid';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function OrderDetail(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        customer_name: "",
        email: "",
        tel_num: "",
        address: "",
        is_active: 0,
    });
    const [backDropOpen, setBackDropOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const storageToken = JSON.parse(sessionStorage.getItem('token'))
    const API_URL = `${Api.apiOnline}`;
    const headers = {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + storageToken.token,
    };

    const handleSubmit = (event) => {
        event.preventDefault();

    }

    function handleChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    function checkMinLenght() {
        minLenght();
    }

    function getUserById() {
        setState(props.rowEdit.row);
    }

    function closeForm() {
        props.handleClose(true);
    }

    const handleClose = (props) => {
        props.handleClose();
    };

    const columDataExample = [
        { field: 'id', headerName: '#', flex: 0.5, sortable: false },
        { field: 'product', headerName: 'Sản phẩm', flex: 0.5, sortable: false },
        { field: 'quantity', headerName: 'Số lượng', flex: 0.5, sortable: false },
        {
            field: 'price', headerName: 'Giá', flex: 0.5, sortable: false,
            renderCell: (params) => (
                <div className='order-customer' >
                    <label>${params.row.price}</label>
                </div>
            )

        },
        {
            field: '', headerName: 'Tổng', flex: 0.5, sortable: false,
            renderCell: (params) => (
                <div className='order-customer' >
                    <label>${params.row.quantity * params.row.price}</label>
                </div>
            )
        },

    ];

    const rowsDataExample = [
        { id: '321548', product: 'Sản phẩm 1', quantity: 1, price: 5, },
        { id: '321553', product: 'Sản phẩm 2', quantity: 2, price: 10 },
    ];

    return (
        <React.Fragment>

            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth={'md'}>
                <ValidatorForm
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}

                >
                    <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                        Thông Tin Đơn Hàng
                    </DialogTitle>
                    <DialogContent dividers>

                        <Grid container alignItems="center" >
                            <Grid item md={3}  >
                                Mã đơn hàng
                            </Grid>
                            <Grid item md={3}>
                                HD0001
                            </Grid>
                            <Grid item md={3} >
                                Khách hàng
                            </Grid>
                            <Grid item md={3}>
                                Nguyễn Văn Anh
                            </Grid>
                            <Grid item md={3} >
                                Số điện thoại
                            </Grid>
                            <Grid item md={3}>
                                0123456789
                            </Grid>
                            <Grid item md={3} >
                                Địa chỉ
                            </Grid>
                            <Grid item md={3}>
                                ABC Quận 3, P12, TPHCM
                            </Grid>
                            
                            <Grid item md={3} >
                                Ngày đặt
                            </Grid>
                            <Grid item md={3}>
                                15/02/2021 8:5:00
                            </Grid>

                            <Grid item md={3} >
                                Hình thức thanh toán
                            </Grid>
                            <Grid item md={3}>
                                COD
                            </Grid>

                            <Grid item md={3}   >
                                Trạng thái
                            </Grid>
                            <Grid item md={9}>
                                <FormControl variant="outlined" fullWidth  >
                                    <TextValidator
                                        style={{ marginTop: 8 }}
                                        name='is_sales'
                                        select
                                        native={'true'}
                                        margin="dense"
                                        fullWidth
                                        id="outlined-basic"
                                        label="Chọn trạng thái"
                                        variant="outlined"
                                        validators={['required']}
                                        errorMessages={['Trạng thái không được để trống']}
                                    >
                                        <option value=""> Chọn trạng thái </option>
                                        <option value='1' >Hoàn thành</option>
                                        <option value='0' >Đang xử lý</option>
                                        <option value='2'>Hủy đơn</option>
                                    </TextValidator>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid md={12} >
                            <DataGrid
                                autoHeight
                                // page={page}
                                // pagination
                                // loading={true}
                                columns={columDataExample}
                                rows={rowsDataExample}
                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[10, 20, 50]}
                                hideFooterPagination={true}
                                disableSelectionOnClick={true}
                                hideFooter
                                disableColumnFilter={true}
                                disableColumnSelector={true}
                                disableColumnMenu={true}
                                disableColumnSelector={true}
                                ColumnUnsortedIcon={true}
                                isCellEditable={false}
                            // getRowId={handleGetRowId}
                            />
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid md={6}>

                            </Grid>
                            <Grid md={6}>
                                <Grid container>
                                    <Grid md={7}><b>Tổng các mục:</b></Grid>
                                    <Grid md={5}>$25</Grid>
                                    <Grid md={4}><b>Thuế:</b></Grid>
                                    <Grid md={3}>10%</Grid>
                                    <Grid md={5}>$2.5</Grid>
                                    <Grid md={7}><b>Tổng hóa đơn:</b></Grid>
                                    <Grid md={5}>$27.5</Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={props.handleClose}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <SimpleBackdrop backDropOpen={backDropOpen} />
        </React.Fragment>
    );
}
