import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm} from 'react-material-ui-form-validator';
import axios from "axios";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import '../styles/style.css'
import SimpleBackdrop from '../layouts/backdrop'
import { Api } from '../config/config';
import CSVReader from "react-csv-reader";
import SimpleAlerts from '../layouts/alert';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ImportCustomer(props) {
    const classes = useStyles();

    const [backDropOpen, setBackDropOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    
    const [errorFileCustomer,setErrorFileCustomer ] = React.useState(false);

    const [errorImport, setErrorImport] = React.useState(false);
    const [errorButton, setErrorButton] = React.useState(false);

    const [dataCSV, setDataCSV] = React.useState([]);
    let storageToken = JSON.parse(sessionStorage.getItem('token'))
    const API_URL = `${Api.apiOnline}`;
    const headers = {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + storageToken.token,
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if( errorFileCustomer )
        {
            return false;
        }

        setBackDropOpen(true);
        setAlertOpen(true);
        setTimeout(() => {
            setAlertOpen(false);
        }, 2000);
        axios.post(`${API_URL}/customers`, dataCSV, { headers })
            .then(response => {
                props.handleImportSuccess();
                setDataCSV(response.data.data)
                setBackDropOpen(false)
                setErrorButton(true)
                // handleClose();
            });
    }

    function showButtonError() {
        setErrorImport(true)
    }


    function handleChange(event) {
        const value = event.target.value;
        // setError('');
        // setState({
        //     ...state,
        //     [event.target.name]: value
        // });
    }

    function handleInputChange() {

    }


    function handleClose() {
        setDataCSV([]);
        setErrorButton(false);
        setErrorImport(false)
        setErrorFileCustomer(false)
        props.handleClose(true);
    }


    const RowLoopData = () => {
        setBackDropOpen(true)
        let data = dataCSV;
        var output = []
        Object.keys(data).forEach(function (key) {
            
            let errorTen = '';
            let errorEmail = '';
            let errorTelNum = '';
            let errorAddress = '';
            if (data[key].error) {
                const { ten_khac_hang, email, telnum, dia_chi } = data[key].error
                if (ten_khac_hang) {
                    errorTen = 'error-ten';
                }
                if (email) {
                    errorEmail = 'error-email';
                }
                if (telnum) {
                    errorTelNum = 'error-tel';
                }
                if (dia_chi)
                    errorAddress = 'error-address';
            }
            output.push(
                <tr key={key}>
                    <td  >{(key * 1 + 1)}</td>
                    <td className={errorTen}  >
                        {data[key].ten_khac_hang}
                    </td>
                    <td className={errorEmail} >
                        {data[key].email}
                    </td>
                    <td className={errorTelNum}  >
                        {data[key].telnum}
                    </td>
                    <td className={errorAddress}  >
                        {data[key].dia_chi}
                    </td>
                </tr>
            )
        })
        setBackDropOpen(false)
        return output
    }

    const handleForce = ((data, fileInfo) => {
        setErrorFileCustomer(false);
        setDataCSV([]);
        setErrorButton(false);
        setErrorImport(false)
        
        // console.log(data);
        // console.log(fileInfo);
        var BreakException = {};
        Object.keys(data).forEach(function (key) { 
            setBackDropOpen(true)
            const { ten_khac_hang, email, telnum, dia_chi } = data[key]
            if( !ten_khac_hang || !email || !telnum || !dia_chi ) {
                setErrorFileCustomer(true);
                setBackDropOpen(false)
                throw BreakException;
            } 
        })
        setDataCSV(data);
        setBackDropOpen(false)
    });

    const checkFileCSVCustomer = () => {

    }

    const removeAccents = (str) => {

        str = str.toLowerCase();
        str = str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

        str = str.replace(/[đĐ]/g, 'd');
        str = str.replace(/([^0-9a-z-\s])/g, '');
        str = str.replace(/(\s+)/g, '-');
        str = str.replace(/-+/g, '_');
        str = str.replace(/^-+|-+$/g, '');

        return str;

    }

    const clearDataCSV = () => {
        setDataCSV([]);
        setErrorFileCustomer(false);
    }


    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => removeAccents(header),
        columns: [null, ' '] //or array of strings
    };

    useEffect(() => {
        
    }, []);


    return (
        <React.Fragment>

            <Dialog fullWidth onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <ValidatorForm
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                >
                    <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                        Import khách hàng
                    </DialogTitle>
                    <DialogContent dividers>

                        <Grid container alignItems="center" >
                            <b>
                                <CSVReader
                                    cssClass="react-csv-input"
                                    label="Tải lên file CSV"
                                    onFileLoaded={handleForce}
                                    parserOptions={papaparseOptions}
                                />
                            </b>
                            {
                                errorButton ?
                                    <Grid item md={12} >
                                        <label style={{ cursor: 'Pointer', color: 'rgb(12 32 176)' }} onClick={showButtonError} > Hiển thị lỗi import </label>
                                    </Grid>
                                    : ''
                            }

                            {
                                errorFileCustomer ? 
                                <Grid item md={12}>
                                    <Box style={{color:'red'}} ><b> File không đúng định dạng (tên khách hàng,email,telnum,địa chỉ)</b> </Box>
                                </Grid>
                                :null
                            }

                        </Grid>

                        <Grid container fullWidth alignItems="center" >
                            {
                                dataCSV.length > 0 && errorImport ?
                                    <table style={{ width: '100%' }} >
                                        <tbody>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên khách hàng</th>
                                                <th>Email</th>
                                                <th>Số điện thoại</th>
                                                <th>Địa chỉ</th>
                                            </tr>

                                            <RowLoopData />
                                        </tbody>
                                    </table>
                                    : null
                            }
                    
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={handleClose}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <SimpleBackdrop backDropOpen={backDropOpen} />
            <SimpleAlerts className='alert-sucess' content='Import' alertOpen={alertOpen} handleClose={handleClose} />
        </React.Fragment>
    );
}

