// export const Api = {
//     apiLocal : 'https://apicrv.shohin.vn/api',
//     apiOnline : 'http://localhost:8000/api',
//     serverLocal: 'https://apicrv.shohin.vn/',
//     serverOnline: 'http://localhost:8000/',
// }

// online
export const Api = {
    apiOnline : 'https://apicrv.shohin.vn/api',
    apiLocal : 'http://localhost:8000/api',
    serverOnline: 'https://apicrv.shohin.vn/',
    serverLocal: 'http://localhost:8000/',
}